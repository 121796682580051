import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonFunctionModule } from './common.function.module';
import { IGbizDelegationUserInfo } from '@interface/gbiz.interface';

@Injectable({
  providedIn: CommonFunctionModule,
})
export class CommonFunction {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) { }

  /**
   * ja.json||en.jsonのパースにより、TranslateServiceから対応する言語内容を取得する
   * @param {string} langPath
   * @returns {string} 対応する言語内容
   */
  getTransValue(langPath: string) {
    const lang = this.translate.store.currentLang;
    let res = this.translate.store.translations[lang];

    langPath.split('.').map((singlePath) => {
      res = res[singlePath];
    });

    return res ? res : langPath;
  }

  /**
   * @description: SnackBarを設定＆表示する
   * @param {string} msg 表示内容
   * @return {void}
   */
  openSnackBarInfo(msg: string): void {
    this.snackBar.open(this.getTransValue(msg), this.getTransValue('BUTTON.CLOSE'), {
      duration: 4000,
    });
  }

  /** 代理申請ステータスから代理ログイン中であるか判定する。 */
  isDelegationApply(delegationStatus: string): boolean {
    return this.notEmpty(delegationStatus);
  }

  /** 空文字でないことを判定する。 */
  notEmpty(text: string): boolean {
    const values = new Set([undefined, 'undefined', null, 'null', '']);

    return !values.has(text);
  }

  /** 空文字であるか判定する */
  isEmptyEx(text: string): boolean {
    return !this.notEmpty(text);
  }

  /**
   * 指定されたテキストが空かどうかをチェックし、空の場合はデフォルトテキストを返します。
   * 空でない場合は元のテキストをそのまま返します。
   * @param text チェック対象のテキスト
   * @param defaultText 空の場合に返すデフォルトテキスト
   * @returns 処理後のテキスト
   */
  getTextOrDefault(text: string, defaultText: string): string {
    return this.isEmptyEx(text) ? defaultText : text;
  }

  /** 委任元がGビズアカウントであるか判別する。 */
  isDelGbizAccount(user: IGbizDelegationUserInfo): boolean {
    const val1 = user.meti_id != undefined && user.meti_id != null;
    const val2 = !this.isEmptyEx(user.user_email);

    return val1 && val2;
  }
}