import { MetaPageHead } from '@shared/services/meta/meta.interface';

export const createMetaTag = (pageName?: string): MetaPageHead => {
  return {
    title: `${pageName ? pageName + ' | ' : ''}jGrants ネットで簡単！補助金申請 | jGrants`,
    description: {
      name: 'description',
      content:
        'jGrantsはデジタル庁が運営する、国や自治体の補助金の電子申請システムです。個人事業主や中小企業等の法人はGビズIDを利用し、補助金・助成金を簡単に検索・申請できます。',
    },
    metas: [
      {
        name: 'keywords',
        content: '補助金,助成金,Jグランツ,jGrants,補助金申請,助成金申請,電子申請,個人事業主,中小企業,法人,GビズID,gbizid',
      },
    ],
  };
};
