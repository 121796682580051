import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CompanyStoreModule } from '@domains/company/store/company.store.module';
import { LoadingModule } from '@organisms/loading/loading.module';
import { CallbackComponent } from './callback.component';
import { CommonFunctionModule } from '@shared/functions/common.function.module';

@NgModule({
  imports: [LoadingModule, RouterModule, CompanyStoreModule, CommonFunctionModule],
  declarations: [CallbackComponent],
})
export class CallbackModule {}
